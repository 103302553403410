import React from 'react'
import * as Styled from './styled'
import Image from 'next/image'
import getConfig from 'next/config'
import handleViewport, { type InjectedViewportProps } from 'react-in-viewport'

const {
  publicRuntimeConfig: { STATIC_ASSETS_COMMON_URL },
} = getConfig()

const data = [
  {
    title: '1. Каждый месяц вам будут открываться новые материалы курса.',
    imagePath: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-how-to-use-1.png`,
    imageWidth: 288,
    imageHeight: 202,
  },
  {
    title:
      '2. Изучайте уроки в комфортном для вас темпе: ставьте их на паузу, перематывайте и смотрите в удобное время.',
    imagePath: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-how-to-use-2.png`,
    imageWidth: 232,
    imageHeight: 177,
  },
  {
    title:
      '3. Проходите тесты, чтобы контролировать успеваемость и видеть свой прогресс.',
    imagePath: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-how-to-use-3.png`,
    imageWidth: 234,
    imageHeight: 157,
  },
  {
    title:
      '4. После прохождения курса, вы получите сертификат, который будет являться подтверждением ваших навыков.',
    imagePath: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-how-to-use-v4.png`,
    imageWidth: 238,
    imageHeight: 168,
  },
]

export const HowToUse = () => {
  return (
    <Styled.Container>
      <Styled.Title>Как проходит обучение на платформе</Styled.Title>

      <Styled.List>
        {data.map((item, index) => (
          <Styled.ListItem key={index}>
            <Styled.ListHeader>
              <Styled.ListTitle>{item.title}</Styled.ListTitle>
            </Styled.ListHeader>

            <Styled.Image index={index}>
              <Image
                src={item.imagePath}
                width={item.imageWidth}
                height={item.imageHeight}
                alt={item.title}
                quality={100}
              />
            </Styled.Image>
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.Container>
  )
}

const HowToUseViewport = (props: InjectedViewportProps<HTMLDivElement>) => {
  const { forwardedRef, enterCount } = props
  return <div ref={forwardedRef}>{enterCount > 0 ? <HowToUse /> : null}</div>
}
export default handleViewport(HowToUseViewport)

import styled, { css } from 'styled-components'

export const Container = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 23px 14.5px;
  max-width: 100%;
  width: 100%;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 25px 20px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 1440px;
    padding: 50px 108px 50px 108px;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.mixins.H4}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H4}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H2}
  }
`

export const List = styled.ul`
  margin-top: 40px;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    grid-row-gap: 0;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 25px;
    grid-column-gap: 16px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 55px;
    grid-column-gap: 24px;
  }
`
export const ListButton = styled.a<{ $color: string }>`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: auto;
  color: ${({ $color }) => $color};
`
export const ListItem = styled.li`
  border-radius: 20px;
  padding: 25px 25px 0 25px;
  position: relative;
  overflow: hidden;
  height: 333px;

  &:nth-child(1) {
    background: #e4fbff;
  }

  &:nth-child(2) {
    background: #f2ffbd;
  }

  &:nth-child(3) {
    background: #fff2e0;
  }

  &:nth-child(4) {
    background: #ffe0e2;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 15px 11px 0 11px;
    height: 200px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 25px 15px 0 25px;
    height: 333px;
  }
`

export const ListTitle = styled.h3``

export const ListButtonText = styled.p`
  margin-right: 5px;
`

export const ListHeader = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mixins.H6}
  height: 116px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    height: 71px;
    ${({ theme }) => theme.mixins.H7}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    height: 116px;
    ${({ theme }) => theme.mixins.H6}
  }
`

export const Image = styled.div<{ index: number }>`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: end;

  right: 0;

  ${({ index }) => {
    if (index === 3) {
      return css`
        transform: rotate(-16deg);
      `
    }
  }}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: ${({ index }) => (index === 0 ? '170px' : '139px')};
    right: 0;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: initial;
    right: ${({ index }) => (index === 1 ? '0' : 'initial')};

    ${({ index }) => {
      if (index === 3) {
        return css`
          right: -20px;
        `
      }
    }}
  }
`
